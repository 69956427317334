import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { routes } from './routes';
import subdomainDictionary from '../component/subDomain/dictionary.json';
import { getRedirectionLink } from '../functions/getRedirectionLink';
import AppLoader from '../component/common/loaders/App-loader';
const SubDomain = lazy(() => import(/* webpackChunkName:"subDomain */ '../component/subDomain'));

//find subdomain and hostname
const subdomains = window.location.hostname.split('.');
const [hostname] = subdomains;

const Routers = ({ redirect, ot, redirectFromQr, scheduleTime }) => {
  return (
    <BrowserRouter>
      <Suspense fallback={<AppLoader />}>
        {/* Show restaurnats for subdomain if availabel */}
        {subdomainDictionary[hostname] ? (
          <SubDomain mall={hostname} details={subdomainDictionary[hostname]} />
        ) : (
          //   Show normal restaurants if subdomain is not availabel *
          <Switch>
            <Route
              exact
              path='/'
              render={() => {
                window.location = getRedirectionLink();
              }}
            />
            <Redirect exact from='/index.php' to='/home' />
            {routes.map(({ location, path, exact, name, comp }, index) => (
              <Route key={index} exact={exact} path={path} component={lazy(comp)} />
            ))}
          </Switch>
        )}
        {redirect ? (
          ot ? (
            <Redirect to={`/r/${redirect}/${ot}`} />
          ) : (
            <Redirect
              to={
                `/r/${redirect}` +
                (scheduleTime ? `?date=${scheduleTime.date}&slot=${scheduleTime.slot}` : '')
              }
            />
          )
        ) : null}
        {redirectFromQr ? <Redirect to={redirectFromQr} /> : null}
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;

import { getLocalItem, setLocalItem } from '../webStorage/localStorage';
import { generateRandomString } from './generateRandomString';
import { apiCartTokenTrack } from '../analytics/tracker/events/bugTrack';
export const setCheckCartToken = (token) => {
  try {
    const maps = {
      NaN: true,
      null: true,
      undefined: true,
    };
    if (token && (!maps[String(token)] || !maps[token])) {
      const validToken = String(token);
      setLocalItem('cart_token', validToken);
      return validToken;
    } else {
      const randomToken = generateRandomString(15);
      setLocalItem('cart_token', randomToken);
      return randomToken;
    }
  } catch (error) {
    console.error('Error setting cart token:', error);
    apiCartTokenTrack({ error: error });
    return;
  }
};

export const getCartTokenLS = () => {
  try {
    const tokenLS = getLocalItem('cart_token');
    return tokenLS || '';
  } catch (error) {
    console.error('Error retrieving cart token:', error);
    apiCartTokenTrack({ error: error });
    return;
  }
};

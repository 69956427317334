//This code defines a saga that listens for 'FETCH_REST_LIST_REQUEST' actions
// it will call api 

import { call, put, takeLatest } from 'redux-saga/effects';
import getNearByRestaurant from './api';
import { fetchRestSuccess, fetchRestFailure } from './action';

function* fetchUserSaga(action) {
  try {
    const rest = yield call( getNearByRestaurant , action.payload);
    yield put(fetchRestSuccess(rest));
  } catch (error) {
    yield put(fetchRestFailure(error.message));
  }
}

export default function* watchFetchRest() {
  yield takeLatest('FETCH_REST_LIST_REQUEST', fetchUserSaga);
}

import { axiosInstanceNext } from '../axios/axios';

export const getTableNumber = async ({ mall, restaurantId, tableId }) => {
  if (!window.navigator.onLine) return { error: 'no_internet' };
  try {
    const response = await axiosInstanceNext.get(
      `api/${mall ? 'food-courts' : 'restaurant/new'}/${restaurantId || mall}/tables/${tableId}`
    );
    if (response.data?.statusCode == 200)
      return { displayTable: response.data?.data?.table_no, id: response.data?.data?.table_id };

    return { error: response.data?.message };
  } catch (error) {
    try {
      window.__bgtracker.track('QR_CODE_ERROR', {
        hitName: 'QR_CODE_ERROR',
        error: error?.response,
        url: `api/${mall ? 'food-courts' : 'restaurant/new'}/${
          restaurantId || mall
        }/tables/${tableId}`,
      });
    } catch (err) {
      console.log('error:', err);
    }
    if (error?.response?.status == 404) return { error: 'invalid_qr_code' };
    else if (!error?.response) return { error: 'no_internet' };
  }
};

import { setOrderSessionTrack } from '../analytics/tracker/events/bugTrack';
import { orderTypeTrack } from '../analytics/tracker/events/orderTypeEvents';
import { getCountryCode } from '../functions/getCountryCode';
import { safeJsonParse } from '../functions/safeJsonParse';
import {
  scheduledTimeFromUrl,
  scheduledTimeFromUrlOtpless,
} from '../functions/scheduledTimeFromUrl';
import {
  ASAP,
  CURRENT_MALL,
  CURRENT_RESTAURANT,
  DELIVERY_TYPE,
  DINEIN_TYPE,
  ORDER_TYPE,
  PICKUP,
  PICKUP_TYPE,
  RESTAURANT_TYPE,
  SCHEDULED,
  TAKEAWAY,
} from './keys/sessionKeys';
import { getLocalItem, removeLocalItem, setLocalItem } from './localStorage';
import { setSessionItem, getSessionItem, removeSessionItem } from './sessionStorage';
export const setCurrentRestaurant = (value) => {
  setLocalItem(CURRENT_RESTAURANT, value);
};
export const getCurrentRestaurant = () => {
  return getLocalItem(CURRENT_RESTAURANT);
};

export const setCurrentMall = (value) => {
  setLocalItem(CURRENT_MALL, value);
};
export const getCurrentMall = () => {
  return getLocalItem(CURRENT_MALL);
};
export const removeCurrnetMall = () => {
  const currentMall = getCurrentMall();
  removeLocalItem(CURRENT_MALL);
  removeSessionItem(currentMall + ORDER_TYPE);
};

export const getSessionKey = () => {
  const currentMall = getCurrentMall();
  const currentRestaurant = getCurrentRestaurant();
  if (currentMall) return currentMall + ORDER_TYPE;
  else if (currentRestaurant) return currentRestaurant + ORDER_TYPE;
  else return null;
};
const setCurrentEnvironment = (mall, restaurnatNameId, rest) => {
  if (mall) return setCurrentMall(mall);
  if (rest == restaurnatNameId) {
    setCurrentRestaurant(restaurnatNameId);
  } else setCurrentRestaurant(rest);
  removeCurrnetMall();
  orderTypeTrack(
    '',
    'Manage order Page',
    `${rest} , ${restaurnatNameId}`,
    '',
    '',
    '',
    'key null check',
    ''
    // getLocalItem(getSessionKey())
  );
};
export const setOrderTypeSession = (data) => {
  try {
    const key = getSessionKey();
    if (!key) {
      return;
    }
    const previousValues = getOrderTypeSession();
    const orderTypeInfo = { ...previousValues, ...data };
    const restaurantValidatedData = restaurantTypeValidation(orderTypeInfo);
    const validatedData = orderTypeValidation(restaurantValidatedData);
    const value = JSON.stringify(validatedData);
    if (validatedData.orderType && validatedData.orderType != 'undefined') {
      setSessionItem(key, value);
    }
    orderTypeTrack('', '', validatedData.orderType, '', '', '', 'Saving Order Type', '', {
      key,
    });
    return validatedData;
  } catch (error) {
    console.log(error);
  }
};
const orderTypeList = [DINEIN_TYPE, DELIVERY_TYPE, PICKUP_TYPE];
export const initializeOrderTypeInfo = ({
  restaurantSetting,
  orderTypeFromUrl,
  pickupType,
  tableId,
  tableNo,
  urlQuery,
  mall,
  rest,
}) => {
  if (!restaurantSetting?.nameid && !mall) return;
  const { date: urlDate, slot } = urlQuery || {};
  setCurrentEnvironment(mall, restaurantSetting?.nameid, rest);
  const sessionValues = getOrderTypeSession();
  if (!orderTypeFromUrl && !urlDate && !slot && !mall && sessionValues) {
    return;
  }
  const convertedOrderType = orderTypeFromUrl && String(Number(orderTypeFromUrl) - 1);
  if (
    mall &&
    ((tableId && tableId == sessionValues?.tableNo) ||
      (!tableId && orderTypeFromUrl && orderTypeFromUrl == sessionValues?.orderType) ||
      (!tableId && !orderTypeList.includes(convertedOrderType) && sessionValues))
  )
    return; // handling foodcourt

  if (!mall && !restaurantSetting) return;
  // if (!mall && !pickupType && convertedOrderType && convertedOrderType == sessionValues?.orderType)
  //   return;
  if (!mall && pickupType && pickupType == sessionValues?.pickupType) return;

  const { orderTime, date, time, epoch, selectedTime } = urlQuery?.slot
    ? scheduledTimeFromUrl(urlQuery, restaurantSetting)
    : scheduledTimeFromUrlOtpless(urlQuery); // set scheduled order from url
  const type = mall ? 3 : restaurantSetting?.type || 3; //show default restaurant type as 3 when restaurant type is not received.

  setRestaurantType(type);

  const values = {
    orderType: convertedOrderType,
    ...(orderTime && { orderTime }),
    pickupType,
    table: tableNo,
    tableNo: tableId,
    ...(date && { date }),
    ...(time && { time }),
    ...(epoch && { epoch }),
    ...(selectedTime && { selectedTime }),
  };
  return setOrderTypeSession(values);
};

const restaurantTypeValidation = (data) => {
  try {
    if (!data) return;

    const restaurantType = getRestaurantType();
    switch (restaurantType) {
      case '1': {
        return {
          orderType: DINEIN_TYPE,
          orderTime: ASAP,
          tableNo: data.tableNo,
          table: data.table,
        };
      }
      case '2': {
        return {
          ...data,
          orderType:
            data.orderType == DELIVERY_TYPE || data.orderType == PICKUP_TYPE
              ? data.orderType
              : DELIVERY_TYPE,
        };
      }
      default: {
        return data;
      }
    }
  } catch (error) {
    console.log(error);
  }
};
const orderTypeValidation = (data) => {
  if (!data) return;
  const countryCode = getCountryCode();
  const currentMall = getCurrentMall();
  //order type validation for foodcourt
  const orderType =
    data?.orderType == DINEIN_TYPE ||
    data?.orderType == PICKUP_TYPE ||
    data?.orderType == DELIVERY_TYPE
      ? data.orderType
      : PICKUP_TYPE;
  if (currentMall) {
    return {
      ...data,
      orderType,
      orderTime: ASAP,
      tableNo:
        (orderType == PICKUP_TYPE && 'pickup') ||
        (orderType == DINEIN_TYPE && data?.tableNo) ||
        'dl',
      table:
        (orderType == PICKUP_TYPE && 'Pickup') || (orderType == DINEIN_TYPE && data?.table) || 'dl',
    };
  }
  if (countryCode === 'id') {
    return {
      ...data,
      orderType:
        data?.orderType == DINEIN_TYPE || data?.orderType == PICKUP_TYPE
          ? data?.orderType
          : PICKUP_TYPE,
      orderTime: !data?.orderTime || data?.pickupType == TAKEAWAY ? ASAP : data?.orderTime,
      tableNo:
        (data?.orderType == DINEIN_TYPE && data?.tableNo) ||
        (data?.orderType == PICKUP_TYPE && data?.pickupType == TAKEAWAY && 'takeaway') ||
        (data?.orderType == PICKUP_TYPE && 'pickup') ||
        'pickup',
      table:
        (data?.orderType == DINEIN_TYPE && data?.table) ||
        (data?.orderType == PICKUP_TYPE && data?.pickupType == TAKEAWAY && 'Takeaway') ||
        (data?.orderType == PICKUP_TYPE && 'Pickup') ||
        (data?.orderType == DINEIN_TYPE && data?.table) ||
        'Pickup',
      time: data?.orderTime == SCHEDULED ? data?.time : undefined,
      epoch: data?.orderTime == SCHEDULED ? data?.epoch : undefined,
      date: data?.orderTime == SCHEDULED ? data?.date : undefined,
      selectedTime: data?.orderTime == SCHEDULED ? data?.selectedTime : undefined,
      pickupType:
        (data?.orderType == PICKUP_TYPE && data?.pickupType == TAKEAWAY && TAKEAWAY) ||
        (data?.orderType == PICKUP_TYPE && PICKUP) ||
        undefined,
    };
  }
  //order type validation for normal restaurants
  return {
    ...data,
    orderType:
      data?.orderType == DINEIN_TYPE ||
      data?.orderType == DELIVERY_TYPE ||
      data?.orderType == PICKUP_TYPE
        ? data?.orderType
        : DELIVERY_TYPE,
    tableNo:
      (data?.orderType == DINEIN_TYPE && data?.tableNo) ||
      (data?.orderType == PICKUP_TYPE && data?.pickupType == TAKEAWAY && 'takeaway') ||
      (data?.orderType == PICKUP_TYPE && 'pickup') ||
      'dl',
    table:
      (data?.orderType == DINEIN_TYPE && data?.table) ||
      (data?.orderType == PICKUP_TYPE && data?.pickupType == TAKEAWAY && 'Takeaway') ||
      (data?.orderType == PICKUP_TYPE && 'Pickup') ||
      (data?.orderType == DINEIN_TYPE && data?.table) ||
      'dl',
    orderTime: !data?.orderTime || data?.pickupType == TAKEAWAY ? ASAP : data?.orderTime,
    time: data?.orderTime == SCHEDULED ? data?.time : undefined,
    epoch: data?.orderTime == SCHEDULED ? data?.epoch : undefined,
    date: data?.orderTime == SCHEDULED ? data?.date : undefined,
    selectedTime: data?.orderTime == SCHEDULED ? data?.selectedTime : undefined,
    pickupType:
      (data?.orderType == PICKUP_TYPE && data?.pickupType == TAKEAWAY && TAKEAWAY) ||
      (data?.orderType == PICKUP_TYPE && PICKUP) ||
      undefined,
  };
};

export const getOrderTypeSession = () => {
  const key = getSessionKey();
  // const ot = JSON.parse(getSessionItem(key));
  const data = getSessionItem(key);
  // getSessionItem(key) && ot?.orderType && getSessionItem(key) != '{}'
  //   ? getSessionItem(key)
  //   : getLocalItem(`${getCurrentRestaurant()}_ot`);
  return safeJsonParse(data);
};
export const getTableId = () => {
  const sessionSetting = getOrderTypeSession();
  if (sessionSetting?.orderType) return sessionSetting.tableNo;

  return 'dl';
};
export const getTableSession = () => {
  const sessionSetting = getOrderTypeSession();
  if (sessionSetting?.table) return sessionSetting?.table;

  return 'dl';
};
export const getOrderType = (isAPI) => {
  // const ot = JSON.parse(getLocalItem(`${getCurrentRestaurant()}_ot`));
  const sessionSetting = getOrderTypeSession();
  if (sessionSetting?.orderType) return sessionSetting?.orderType;
  return '';
};

export const getOrderTypeName = (orderTypeValue, tableIdValue, explicit = false) => {
  const sessionSetting = getOrderTypeSession();
  let orderType, tableId;
  if (explicit) {
    orderType = orderTypeValue;
    tableId = tableIdValue;
  } else {
    orderType = sessionSetting?.orderType;
    tableId = sessionSetting?.pickupType;
  }
  if (orderType == DINEIN_TYPE) return 'Dine-in';
  if (orderType == DELIVERY_TYPE) return 'Delivery';
  if (orderType == PICKUP_TYPE && tableId === TAKEAWAY) return 'Takeaway';
  if (orderType == PICKUP_TYPE) return 'Pickup';
};

export const setRestaurantType = (value) => {
  if (value) setSessionItem(RESTAURANT_TYPE, value);
};
export const getRestaurantType = () => {
  return getSessionItem(RESTAURANT_TYPE);
};
export const orderTypeRetrival = (urlParams, cookies, query) => {
  setCurrentRestaurant(urlParams?.name);
  if (urlParams?.ot == 1) {
    setOrderTypeSession({
      orderType: DINEIN_TYPE,
      tableNo: cookies.tableId || query.tableId,
      table: cookies.tableNo || query.tableNo,
    });
    setOrderSessionTrack({
      fileName: 'manageOrderType.js',
      functionName: 'orderTypeRetrival1',
      orderType: DINEIN_TYPE,
      tableNo: cookies?.tableId || query?.tableId,
      table: cookies?.tableNo || query?.tableNo,
    });
  } else if (urlParams?.ot == 3) {
    setOrderTypeSession({
      orderType: PICKUP_TYPE,
      pickupType: urlParams.pickupType,
    });
    setOrderSessionTrack({
      fileName: 'manageOrderType.js',
      functionName: 'orderTypeRetrival2',
      orderType: PICKUP_TYPE,
      pickupType: urlParams?.pickupType,
    });
  } else if (urlParams?.ot == 2) {
    setOrderTypeSession({ orderType: DELIVERY_TYPE });
    setOrderSessionTrack({
      fileName: 'manageOrderType.js',
      functionName: 'orderTypeRetrival3',
      orderType: DELIVERY_TYPE,
    });
  }
};

// TODO: Experimenting, will remove this later

export const apiCartTokenTrack = (data) => {
  if (!data) return;
  try {
    window.__bgtracker.track('API_CART_TOKEN_TRACK', { hitName: 'API_CART_TOKEN_TRACK', ...data });
  } catch (error) {
    console.log(error);
  }
};

export const cookiesCartTokenTrack = (data) => {
  if (!data) return;
  try {
    window.__bgtracker.track('COOKIES_CART_TOKEN_TRACK', {
      hitName: 'COOKIES_CART_TOKEN_TRACK',
      ...data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const setCookieTableInfoTrack = (data) => {
  if (!data) return;
  try {
    window.__bgtracker.track('SET_COOKIE_TABLE_INFO_TRACK', {
      hitName: 'SET_COOKIE_TABLE_INFO_TRACK',
      ...data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const setOrderSessionTrack = (data) => {
  if (!data) return;
  try {
    window.__bgtracker.track('SET_ORDER_SESSION_TRACK', {
      hitName: 'SET_ORDER_SESSION_TRACK',
      ...data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const createOrderTrack = (data) => {
  if (!data) return;
  try {
    window.__bgtracker.track('CREATE_ORDER_TRACK', {
      hitName: 'CREATE_ORDER_TRACK',
      ...data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const payNowClickTrack = (data) => {
  if (!data) return;
  try {
    window.__bgtracker.track('PAY_NOW_CLICK_TRACK', {
      hitName: 'PAY_NOW_CLICK_TRACK',
      ...data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const addToCartHttpTrack = (data) => {
  if (!data) return;
  try {
    window.__bgtracker.track('ADD_TO_CART_HTTP_TRACK', {
      hitName: 'ADD_TO_CART_HTTP_TRACK',
      ...data,
    });
  } catch (error) {
    console.log(error);
  }
};

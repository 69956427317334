import { getRestaurantType } from '../../../webStorage/manageOrderType';

export const orderTypeTrack = (
  cartToken,
  page,
  Order_Type_ID,
  orderId,
  phone,
  orderNo,
  step,
  url,
  data
) => {
  try {
    window.__bgtracker.track('ORDER_TYPE_TRACKING', {
      hitName: 'ORDER_TYPE_TRACKING',
      cartToken,
      page,
      Order_Type_ID,
      orderId,
      phone,
      orderNo,
      restaurantType: getRestaurantType(),
      step,
      url,
      data,
    });
  } catch (error) {
    console.log(error);
  }
};
